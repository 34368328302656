/* Everything */
.graphiql-container {
  display: flex;
  height: 100%;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.root {
  background: url("graphiql/resources/mainBg.svg") no-repeat 70% 45%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  width: 100vw;
}

.root::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #090218;
  z-index: -1;
}

/* The sidebar */
.graphiql-container .graphiql-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--px-8);
  width: var(--sidebar-width);
}

.graphiql-container .sidebar-left {
  justify-content: flex-end;
}

.graphiql-container .graphiql-sidebar .graphiql-sidebar-section {
  display: flex;
  flex-direction: column;
  gap: var(--px-8);
}

.graphiql-container .graphiql-sidebar button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  height: calc(60px - (2 * var(--px-8)));
  width: calc(60px - (2 * var(--px-8)));
}

.graphiql-container .graphiql-sidebar button.active {
  color: hsla(var(--color-neutral), 1);
}
.graphiql-container .graphiql-sidebar button:not(:first-child) {
  margin-top: var(--px-4);
}
.graphiql-container .graphiql-sidebar button > svg {
  height: 40px;
  width: 40px;
}

/* The main content, i.e. everything except the sidebar */
.graphiql-container .graphiql-main {
  background: linear-gradient(0deg, rgba(9, 2, 24, 0.5), rgba(9, 2, 24, 0.5)),
  linear-gradient(0deg, rgba(48, 43, 65, 0.5), rgba(48, 43, 65, 0.5));
  border: 1px solid #302B41;
  box-shadow: 0 4px 15px 0 rgba(89, 91, 212, 0.2);
  margin: 10px 0;
  border-radius: 22px;
  display: flex;
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

/* The current session and tabs */
.graphiql-container .graphiql-sessions {
  background-color: transparent;
  /* Adding the 8px of padding to the inner border radius of the query editor */
  border-radius: calc(var(--border-radius-12) + var(--px-8));
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  margin: var(--px-16);
  min-width: 0;
}

/* The session header containing tabs and the logo */
.graphiql-container .graphiql-session-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: var(--session-header-height);
}

.graphiql-session-header .graphiql-tabs {
  padding-top: 0;
}

.graphiql-session-header .graphiql-tabs::-webkit-scrollbar {
  height: 5px;
  background-color: #090218;
}

.graphiql-session-header .graphiql-tabs::-webkit-scrollbar-thumb {
  background-color: #332b52;
}

/*.graphiql-sessions .graphiql-header-banner {*/
.graphiql-header {
  width: 100%;
  height: 130px;
  font-size: 40px;
  z-index: 999;
  padding-top: 20px;
  margin-bottom: 50px;
  background: transparent;
  display: flex;
  justify-content: space-between;
}

.graphiql-header-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.header-logo {
  display: block;
  width: 84px;
  height: 40px;
}

.graphiql-header-subtitle {
  font-family: 'Source Sans Pro', 'sans-serif';
  font-size: 16px;
  color: #C0BACF;
}

.graphiql-header-banner {
  width: 40%;
  height: 90px;
  background: linear-gradient(93.48deg, rgba(14, 6, 32, 0.8) 23.19%, rgba(21, 12, 48, 0.8) 68.8%),
  linear-gradient(0deg, rgba(39, 33, 56, 0.8), rgba(39, 33, 56, 0.8));
  border: 1px solid #272138;
  border-radius: 22px;
  margin-right: 20px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Source Sans Pro', 'sans-serif';
  font-weight: 600;
  font-size: 16px;
  color: #fff;
}

.banner-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-item-image {
  width: 60px;
  height: 60px;
  margin-right: 8px;
}

.banner-button {
  padding: 10px 24px;
  background: rgba(89, 91, 213, 0.44);
  border: 1px solid rgba(92, 93, 220, 0.5);
  border-radius: 14px;
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 1680px) {
  .graphiql-header-banner {
    font-size: 14px;
    height: 70px;
  }
  .banner-item-image {
    width: 40px;
    height: 40px;
  }
  .banner-button {
    padding: 10px 18px;
  }
}

/* The button to add a new tab */
button.graphiql-tab-add {
  padding: var(--px-4);
  border: 1px solid #231B31;
  border-radius: 8px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button.graphiql-tab-add > svg {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: block;
  height: var(--px-16);
  width: var(--px-16);
}
.graphiql-add-tab-wrapper {
  padding: var(--px-12) 0;
}

/* The right-hand-side of the session header */
.graphiql-container .graphiql-session-header-right {
  align-items: stretch;
  display: flex;
}

/* The GraphiQL logo */
.graphiql-container .graphiql-logo {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-medium);
  padding: var(--px-12) var(--px-16);
}

/* Undo default link styling for the default GraphiQL logo link */
.graphiql-container .graphiql-logo .graphiql-logo-link {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  text-decoration: none;
}

/* The editor of the session */
.graphiql-container .graphiql-session {
  display: flex;
  flex: 1;
  min-height: 20vh;
  margin-top: 10px;
}

/* All editors (query, variable, headers) */
.graphiql-container .graphiql-editors {
  background-color: #0F0822;
  border-radius: calc(var(--border-radius-12));
  box-shadow: var(--popover-box-shadow);
  display: flex;
  flex: 1;
  flex-direction: column;
}
.graphiql-container .graphiql-editors.full-height {
  margin-top: calc(var(--px-8) - var(--session-header-height));
}

/* The query editor and the toolbar */
.graphiql-container .graphiql-query-editor {
  border-bottom: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  display: flex;
  flex: 1;
  padding: var(--px-16);
}

/* The query editor */
.graphiql-container .graphiql-query-editor-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.graphiql-query-editor-header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin-top: 15px;
}

.graphiql-query-editor-header-title {
  font-weight: 600;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
}

.graphiql-query-editor-header-title span {
  margin-left: 10px;
}

.graphiql-query-editor-header-controls {
  display: flex;
  align-items: center;
}

.header-controls-dropdown {
  margin-right: 20px;
  width: 85px;
}

.header-controls-dropdown select {
  font-family: 'Source Sans Pro', 'sans-serif';
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
}

/* The vertical toolbar next to the query editor */
.graphiql-container .graphiql-toolbar {
  margin-left: var(--px-16);
  width: var(--toolbar-width);
}
.graphiql-container .graphiql-toolbar > * + * {
  margin-top: var(--px-8);
}

/* The toolbar icons */
.graphiql-toolbar-icon {
  color: hsla(var(--color-neutral), var(--alpha-tertiary));
  display: block;
  height: 30px;
  width: 30px;
}

/* The tab bar for editor tools */
.graphiql-container .graphiql-editor-tools {
  align-items: center;
  cursor: row-resize;
  display: flex;
  justify-content: space-between;
  padding: var(--px-8);
}
.graphiql-container .graphiql-editor-tools button {
  font-weight: 400;
  font-size: 16px;
  color: #666571;
}
.graphiql-container .graphiql-editor-tools button.active {
  color: hsla(var(--color-neutral), 1);
}

/* The tab buttons to switch between editor tools */
.graphiql-container .graphiql-editor-tools-tabs {
  cursor: auto;
  display: flex;
}
.graphiql-container .graphiql-editor-tools-tabs > button {
  padding: var(--px-4) var(--px-8);
}
.graphiql-container .graphiql-editor-tools-tabs > button + button {
  margin-left: var(--px-8);
}

/* An editor tool, e.g. variable or header editor */
.graphiql-container .graphiql-editor-tool {
  flex: 1;
  padding: var(--px-16);
}

/**
   * The way CodeMirror editors are styled they overflow their containing
   * element. For some OS-browser-combinations this might cause overlap issues,
   * setting the position of this to `relative` makes sure this element will
   * always be on top of any editors.
   */
.graphiql-container .graphiql-toolbar,
.graphiql-container .graphiql-editor-tools,
.graphiql-container .graphiql-editor-tool {
  position: relative;
}

/* The response view */
.graphiql-container .graphiql-response {
  background-color: #0F0822;
  border-radius: calc(var(--border-radius-12));
  box-shadow: var(--popover-box-shadow);
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.graphiql-response-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto !important;
  border-radius: calc(var(--border-radius-12));
}

.active {
  outline: #595BD4 solid 2px;
}

.graphiql-response-header {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 15px;
}

.graphiql-response-header > span {
  font-weight: 600;
  font-size: 18px;
  color: white;
  margin-left: 10px;
}

/* The results editor wrapping container */
.graphiql-container .graphiql-response .result-window {
  position: relative;
  flex: 1;
}

/* The footer below the response view */
.graphiql-footer {
  width: 100%;
  height: 80px;
  background: radial-gradient(278.12% 1089.85% at 50% 50%, #090218 0%, #0B051D 100%);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: rgba(154, 151, 174, 1);
  margin-top: 20px;
  padding: 20px 0;
  font-size: 14px;
  border-top: 1px solid rgba(39, 33, 56, 1);
  font-family: 'Source Sans Pro', sans-serif;
}

.graphiql-footer-left {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.footer-aboutUs {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 15px;
}

.graphiql-footer-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 20px;
}

.graphiql-footer .footer-madeBy {
  display: flex;
}

.graphiql-footer .footer-policy a {
  color: #9A97AE;
}

.graphiql-footer .footer-socials {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.graphiql-footer .footer-socials > a {
  margin-right: 10px;
}

/* The plugin container */
.graphiql-container .graphiql-plugin {
  height: 100%;
  background-color: #0F0822;
  border-radius: calc(var(--border-radius-12));
  box-shadow: var(--popover-box-shadow);
  border-left: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.graphiql-container .graphiql-plugin::-webkit-scrollbar {
  background-color: #0F0822;
  width: 5px;
  height: 5px;
}

.graphiql-container .graphiql-plugin::-webkit-scrollbar-thumb {
  background-color: #332b52;
}

/* Generic drag bar for horizontal resizing */
.graphiql-container .graphiql-horizontal-drag-bar {
  width: var(--px-12);
  cursor: col-resize;
}
.graphiql-container .graphiql-horizontal-drag-bar:hover::after {
  border: var(--px-2) solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  border-radius: var(--border-radius-2);
  content: '';
  display: block;
  height: 25%;
  margin: 0 auto;
  position: relative;
  /* (100% - 25%) / 2 = 37.5% */
  top: 37.5%;
  width: 0;
}

.graphiql-container .graphiql-chevron-icon {
  color: hsla(var(--color-neutral), var(--alpha-tertiary));
  display: block;
  height: var(--px-12);
  margin: var(--px-12);
  width: var(--px-12);
}

/* Generic spin animation */
.graphiql-spin {
  animation: spin 0.8s linear 0s infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* The header of the settings dialog */
reach-portal .graphiql-dialog-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--px-24);
}

/* The title of the settings dialog */
reach-portal .graphiql-dialog-title {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-medium);
}

/* A section inside the settings dialog */
reach-portal .graphiql-dialog-section {
  align-items: center;
  border-top: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  display: flex;
  justify-content: space-between;
  padding: var(--px-24);
}
reach-portal .graphiql-dialog-section > :not(:first-child) {
  margin-left: var(--px-24);
}

/* The section title in the settings dialog */
reach-portal .graphiql-dialog-section-title {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-medium);
}

/* The section caption in the settings dialog */
reach-portal .graphiql-dialog-section-caption {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
}

reach-portal .graphiql-warning-text {
  color: hsl(var(--color-warning));
  font-weight: var(--font-weight-medium);
}

reach-portal .graphiql-table {
  border-collapse: collapse;
  width: 100%;
}
reach-portal .graphiql-table :is(th, td) {
  border: 1px solid hsla(var(--color-neutral), var(--alpha-background-heavy));
  padding: var(--px-8) var(--px-12);
}

/* A single key the short-key dialog */
reach-portal .graphiql-key {
  background-color: hsla(var(--color-neutral), var(--alpha-background-medium));
  border-radius: var(--border-radius-4);
  padding: var(--px-4);
}

/* Avoid showing native tooltips for icons with titles */
.graphiql-container svg {
  pointer-events: none;
}
